<template>
  <footer class="footer" :class="{ white: color }">
    <div class="footer__wrapper">
      <div class="footer__content">
        <div v-if="!nologo" class="footer__logo">
          <the-logo />
        </div>
        <div class="footer__box">
          <div class="footer__block footer__block--left">
            <div class="footer__hl">
             Adresse
            </div>
            <div class="footer__item">
              {{ kontakt.company }}
            </div>
            <div class="footer__item">
              <a :href="kontakt.googlemaps" target="_blank" class="footer__a"
                >{{ kontakt.address.street }}
              </a>
            </div>
            <div class="footer__item">
              <a :href="kontakt.googlemaps" target="_blank" class="footer__a"
                >{{ kontakt.address.plz }} {{ kontakt.address.location }}
              </a>
            </div>
          </div>
          <div class="footer__block">
            <div class="footer__hl">
              Öffnungszeiten
            </div>
         

            <div
            v-for="(time, index) in kontakt.oeffnungszeiten"
            :key="index"
            class="footer__item"
          >
         {{ time.tag }}
         <i class="bold"> {{ time.text }}</i> 
          </div>
          </div>
          <div class="footer__block">
            <div class="footer__hl">
              Kontakt
            </div>
            <div class="footer__item">
              <a :href="getMail" class="footer__a"> {{ kontakt.email }}</a>
            </div>
            <div class="footer__item">
              <a :href="getPhone" class="footer__a">{{ kontakt.phone }}</a>
            </div>
            <div class="footer__item">
              <a :href="getMobile" class="footer__a">{{ kontakt.mobile }}</a>
            </div>
          </div>
          <div class="footer__block">
            <div class="footer__hl">
              Networks
            </div>
            <div class="footer__item">
              <a :href="kontakt.instagram" class="footer__a" target="_blank">
                Instagram
              </a>
            </div>
            <div class="footer__item">
              <a :href="kontakt.facebook" class="footer__a" target="_blank">
                Facebook
              </a>
            </div>
          </div>
          <div class="footer__block footer__block">
            <div class="footer__hl">
             Wichtiges
            </div>
            <div class="footer__item">
              <router-link :to="prefixPath('datenschutz')" class="footer__a">
                Datenschutz
              </router-link>
            </div>
            <div class="footer__item">
              <router-link :to="prefixPath('impressum')" class="footer__a">
                Impressum
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import contactInformation from "@/utilities/siteinformation/contactInformation";
import { useStore } from "vuex";

import TheLogo from "../../ui/logo/TheLogo.vue";
import { onMounted } from "@vue/runtime-core";
export default {
  components: { TheLogo },
  props: { nologo: Boolean, color: String },
  setup() {
    const store = useStore();
    const kontakt = contactInformation;
    const publicPath = process.env.BASE_URL;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;
    const getMobile = "tel:" + kontakt.mobile;

    const { getPath } = getPathFunction();

    function prefixPath(el) {
      return publicPath + el;
    }

    function closeMenu() {
      store.commit("setMenuState", false);
    }

    onMounted(() => {
      let els = document.querySelectorAll(".footer__item");
      els.forEach((element) => {
        element.addEventListener("click", closeMenu);
      });
    });
    return { kontakt, getMail, getPhone,getMobile, prefixPath, getPath };
  },
};
</script>


