const kontakt = {
  title: "Kontakt",
  company: "Autotechnik Gabriel GmbH",
  firstname: "Gabriel",
  lastname: "Mc Closkey",
  address: {
    street: "Mühlweg 2",
    plz: "88239",
    location: "Wangen",
    country: "Deutschland",
  },
  email: "info@autotechnik-gabriel.de",
  phone: "+49 7522 1231",
  mobile: "+49 17670459888",
  fax: "xxxx",
  googlemaps: "https://goo.gl/maps/ULW4Lg7Dfe8TMkGg7",

  facebook: "https://www.instagram.com/kfz_gabriel_mccloskey",
  instagram: "https://www.instagram.com/kfz_gabriel_mccloskey",
  date: {
    day_1: "",
  },
  oeffnungszeiten: [
    {
      tag: "Samstag",
      text: "Geschlossen",
    },
    {
      tag: "Sonntag",
      text: "Geschlossen",
    },
    {
      tag: "Montag",
      text: "08:00–12:00, 13:00–17:30",
    },
    {
      tag: "Dienstag",
      text: "08:00–12:00, 13:00–17:30",
    },
    {
      tag: "Mittwoch",
      text: "08:00–12:00, 13:00–17:30",
    },
    {
      tag: "Donnerstag",
      text: "08:00–12:00, 13:00–17:30",
    },
    {
      tag: "Freitag",
      text: "08:00–12:00, 13:00–17:30",
    },
  ],
};

export default kontakt;
