export default function() {
  const publicPath = process.env.BASE_URL;

  const getPath = function (path) {
    
    try {
      let newP = path.split(".jpg");
    if (newP.length >= 2) {
      return publicPath + path;
    }
    newP = path.split(".jpeg");
    if (newP.length >= 2) {
      return publicPath + path;
    }
    newP = path.split(".png");
    if (newP.length >= 2) {
      return publicPath + path;
    }
    newP = path.split(".svg");
    if (newP.length >= 2) {
      return publicPath + path;
    }
    newP = path.split(".webm");
    if (newP.length >= 2) {
      return publicPath + path;
    }
    newP = path.split(".mp4");
    if (newP.length >= 2) {
      return publicPath + path;
    }
    return publicPath + path + ".jpg";
    } catch (error) {
      console.log("no path found :/");
    }
    
  };
  const prefixPath = function(path) {
    return publicPath + path;
  };

  const setBackGroundPath = (path) => {
    return "url(" + getPath(path) + ")";
  };

  return { prefixPath, getPath, setBackGroundPath };
}
