<template>
  <div class="thelogo" ref="LOGO">
    <div class="thelogo__wrapper">
      <svg viewBox="0 0 133.01 137.13" xmlns="http://www.w3.org/2000/svg">
        <path
        class="thelogo__bg--1"
          d="m102.35 47.62c24.28.24 46.57 5.13 65.55 9.71l1 .24.06 1a155.1 155.1 0 0 1 -3.18 42.9 120.63 120.63 0 0 1 -13.57 34.58 124.85 124.85 0 0 1 -21.79 27.25 162.31 162.31 0 0 1 -27.81 21l-.72.44-.72-.44a158 158 0 0 1 -27.77-21.38 122.05 122.05 0 0 1 -21.28-27.55 121.61 121.61 0 0 1 -12.87-34.86 162.06 162.06 0 0 1 -2.6-43.2l.07-1 1-.25a260.37 260.37 0 0 1 56.8-8.36c2.59-.09 5.24-.12 7.88-.09z"
          fill="#fff"
          fill-rule="evenodd"
          transform="translate(-36.24 -47.61)"
        />
        <path
        class="thelogo__bg--2"
          d="m94.51 49.09a261.42 261.42 0 0 0 -56.51 8.31c-4.14 63.2 23.64 101 63.86 125.72 39.84-24.1 69.28-61.74 65.69-124.45-21-5.07-45.94-10.49-73.04-9.58z"
          fill-rule="evenodd"
          transform="translate(-36.24 -47.61)"
        />
        <g fill="#fff">
          <path      class="thelogo__outline--2" d="m29.11 90.22-1.07-1.09 75.25-73.79 1.07 1.09z" />
          <path      class="thelogo__outline--3" d="m102.55 90.93-73.79-75.24 1.09-1.07 73.79 75.24z" />
          <path

          class="thelogo__path--k"
            d="m68.79 101.17-.8 1.46v4.93h-2.52l.16-16.07h2.52l-.07 7 3.33-6.92h2.52l-3.58 7.13 3.42 8.94h-2.59z"
            transform="translate(-36.24 -47.61)"
          />
          <path
          class="thelogo__path--f"
            d="m102.5 70.64h3.26v2.29h-3.26l-.06 6.64h-2.53l.09-16.1 6.68.07v2.29h-4.16z"
            transform="translate(-36.24 -47.61)"
          />
          <path
          class="thelogo__path--z"
            d="m139 92.19v2.25l-4.91 11.47h4.8v2.3l-7.39-.08v-2.25l4.91-11.47h-4.57v-2.3z"
            transform="translate(-36.24 -47.61)"
          />
          <path
          class="thelogo__path--w"
            d="m127.27 132.49v-.35a6.77 6.77 0 0 0 -4.11-3.49h-1c-1.45.47-3 2.09-5.1 2.6s-5.23.29-8.29.26l-5.14-.05s2 0 0-.06-8.92 0-12.57-.25c-1.17-.06-2.24.1-3.22 0-2-.26-3-2.32-4.81-2.69-1.63-.34-3.74.18-4.62 1.45 1.27.58 3.35.37 4.25 1.31a4.52 4.52 0 0 1 -.73 3.68c-1.77.4-3.35-1-4.94-.74v.34a6.8 6.8 0 0 0 4.12 3.5h1a9.71 9.71 0 0 0 2.06-1.13h.07a9.92 9.92 0 0 1 3-1.43c2.18-.54 5.24-.3 8.3-.27l5.08.05.06.07c1.92 0 8.92.05 12.56.24 1.18.07 2.25-.09 3.23 0 2 .25 3 2.32 4.8 2.69 1.63.33 3.75-.19 4.63-1.45-1.27-.58-3.35-.37-4.25-1.31a4.48 4.48 0 0 1 .73-3.68c1.77-.4 3.35 1 4.94.74"
            transform="translate(-36.24 -47.61)"
          />


          <g fill="#fff"
            class="thelogo__g--1"
          >
            <path
        
              d="m94.35 153.39a1.13 1.13 0 0 1 -.09.45 1.18 1.18 0 0 1 -.27.4 1.36 1.36 0 0 1 -.41.26 1.25 1.25 0 0 1 -.51.1 1.06 1.06 0 0 1 -.48-.11.77.77 0 0 1 -.32-.3.93.93 0 0 1 -.11-.44 1.16 1.16 0 0 1 .18-.59 1.33 1.33 0 0 1 .47-.44 1.31 1.31 0 0 1 .64-.15 1.12 1.12 0 0 1 .5.11.74.74 0 0 1 .3.31.7.7 0 0 1 .1.4zm-.54-.16a.55.55 0 0 0 -.09-.34.3.3 0 0 0 -.27-.13.42.42 0 0 0 -.31.13 1 1 0 0 0 -.24.32 3 3 0 0 0 -.14.39 2.93 2.93 0 0 0 -.05.35.53.53 0 0 0 .09.32.29.29 0 0 0 .26.13.47.47 0 0 0 .32-.12 1 1 0 0 0 .24-.32 2.12 2.12 0 0 0 .14-.4 3 3 0 0 0 .05-.33z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m92.72 151.38-.06.21h-.13-.12a.83.83 0 0 0 -.18.08l-.26.17-1.14.81.72 1.33a.85.85 0 0 0 .1.17.34.34 0 0 0 .12.09.58.58 0 0 0 .21 0l-.07.21h-1.41l.07-.22a.82.82 0 0 0 .24 0 .12.12 0 0 0 .09-.12.25.25 0 0 0 0-.14l-.52-1-.44.32-.16.5a.74.74 0 0 0 0 .2.19.19 0 0 0 .08.17.4.4 0 0 0 .22.06l-.06.21h-1.41l.07-.22a.31.31 0 0 0 .27-.08.69.69 0 0 0 .13-.26l.61-1.94a1.72 1.72 0 0 0 0-.2c0-.15-.1-.23-.3-.23l.07-.21h1.38l-.07.21h-.24a.23.23 0 0 0 -.12.1 1 1 0 0 0 -.11.23l-.3 1.17 1.4-1c.14-.1.21-.19.21-.26s-.1-.16-.28-.16h-.07l.07-.21z"
              transform="translate(-36.24 -47.61)"
            />
          
            <path
              d="m99 154c-.24.42-.53.64-.86.63a.33.33 0 0 1 -.27-.1.28.28 0 0 1 -.1-.22 1.08 1.08 0 0 1 0-.25l.19-.58a1.35 1.35 0 0 0 .06-.27.13.13 0 0 0 0-.13.17.17 0 0 0 -.13-.05.64.64 0 0 0 -.42.21 2.34 2.34 0 0 0 -.42.56 3.71 3.71 0 0 0 -.3.68v.11h-.55l.36-1.16a.91.91 0 0 0 0-.22.18.18 0 0 0 0-.13s-.07-.05-.13-.06a.58.58 0 0 0 -.33.14 1.83 1.83 0 0 0 -.32.35 3.82 3.82 0 0 0 -.29.48 3.77 3.77 0 0 0 -.21.52v.07h-.58l.42-1.33a1.25 1.25 0 0 0 .07-.27.11.11 0 0 0 0-.06h-.06-.08a.56.56 0 0 0 -.11.11.84.84 0 0 0 -.14.22h-.2a1.4 1.4 0 0 1 .29-.44.66.66 0 0 1 .48-.2h.18a.26.26 0 0 1 .14.11.33.33 0 0 1 0 .2 1.32 1.32 0 0 1 -.08.39l-.07.22a4.1 4.1 0 0 1 .29-.4 2.18 2.18 0 0 1 .31-.3 1.18 1.18 0 0 1 .31-.19.93.93 0 0 1 .29-.05.45.45 0 0 1 .31.1.34.34 0 0 1 .12.27 1.41 1.41 0 0 1 -.07.37l-.07.2a2.92 2.92 0 0 1 .32-.44 1.69 1.69 0 0 1 .41-.35.92.92 0 0 1 .48-.14.36.36 0 0 1 .24.07.37.37 0 0 1 .14.14.41.41 0 0 1 0 .17.37.37 0 0 1 0 .12l-.06.22-.23.72a.85.85 0 0 0 0 .18.07.07 0 0 0 .08.08.4.4 0 0 0 .16-.07 1.14 1.14 0 0 0 .21-.27z"
              transform="translate(-36.24 -47.61)"
            />

          
            <path
              d="m101.62 153.34a1.18 1.18 0 0 1 -.1.48 1.45 1.45 0 0 1 -.26.43 1.49 1.49 0 0 1 -.4.31 1.21 1.21 0 0 1 -.49.11.72.72 0 0 1 -.32-.07 1.24 1.24 0 0 1 -.22-.18l-.25.81a.38.38 0 0 0 0 .14.11.11 0 0 0 .08.1.84.84 0 0 0 .26 0h.08l-.06.21h-1.47l.06-.2h.1.23a.24.24 0 0 0 .11-.09 2.21 2.21 0 0 0 .09-.24l.62-2a.41.41 0 0 0 0-.17.19.19 0 0 0 0-.07.07.07 0 0 0 -.06 0s-.08 0-.14.08a1 1 0 0 0 -.18.3h-.22a2.44 2.44 0 0 1 .14-.28.89.89 0 0 1 .23-.25.62.62 0 0 1 .38-.12.34.34 0 0 1 .38.4 1.07 1.07 0 0 1 .76-.39.59.59 0 0 1 .39.12.54.54 0 0 1 .2.28.79.79 0 0 1 .06.29zm-.57-.07a.43.43 0 0 0 -.05-.27.24.24 0 0 0 -.21-.12.44.44 0 0 0 -.32.14 1.28 1.28 0 0 0 -.26.36 1.39 1.39 0 0 0 -.12.28 1.77 1.77 0 0 0 -.08.25.88.88 0 0 0 0 .2.41.41 0 0 0 .09.26.3.3 0 0 0 .24.11.49.49 0 0 0 .39-.21 1.42 1.42 0 0 0 .27-.5 1.58 1.58 0 0 0 .05-.5z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m104 153.05a.49.49 0 0 1 -.06.22.8.8 0 0 1 -.21.26 1.36 1.36 0 0 1 -.45.25 2.65 2.65 0 0 1 -.72.13h-.06a1.11 1.11 0 0 0 0 .17.34.34 0 0 0 .08.25.36.36 0 0 0 .24.08.87.87 0 0 0 .67-.37h.25a1.81 1.81 0 0 1 -.44.43 1.18 1.18 0 0 1 -.7.21.75.75 0 0 1 -.47-.15.54.54 0 0 1 -.2-.45 1.26 1.26 0 0 1 .21-.68 1.4 1.4 0 0 1 .54-.54 1.42 1.42 0 0 1 .75-.21.72.72 0 0 1 .46.12.32.32 0 0 1 .11.28zm-.49 0a.24.24 0 0 0 0-.14.2.2 0 0 0 -.15-.07.5.5 0 0 0 -.3.11 1.49 1.49 0 0 0 -.23.27 1.77 1.77 0 0 0 -.15.31l-.08.19a1.62 1.62 0 0 0 .72-.26.52.52 0 0 0 .15-.46z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m105.67 152.75-.07.22h-.46l-.37 1.14a1.63 1.63 0 0 0 0 .18.12.12 0 0 0 0 .09.11.11 0 0 0 .09 0 .29.29 0 0 0 .23-.12 2.9 2.9 0 0 0 .19-.29h.22a1.47 1.47 0 0 1 -.3.45.77.77 0 0 1 -.31.21.63.63 0 0 1 -.26.05.4.4 0 0 1 -.31-.12.45.45 0 0 1 -.11-.31 1.25 1.25 0 0 1 .08-.36l.31-1h-.44l.07-.21h.43l.22-.7h.56l-.22.71z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m107.78 153.09a.79.79 0 0 1 -.07.22.77.77 0 0 1 -.21.26 1.38 1.38 0 0 1 -.44.24 2.78 2.78 0 0 1 -.73.14 1.11 1.11 0 0 0 0 .17.32.32 0 0 0 .08.25.33.33 0 0 0 .24.07.87.87 0 0 0 .67-.37h.24a1.64 1.64 0 0 1 -.44.43 1.15 1.15 0 0 1 -.69.21.71.71 0 0 1 -.48-.16.49.49 0 0 1 -.19-.44 1.4 1.4 0 0 1 .2-.69 1.64 1.64 0 0 1 .54-.54 1.41 1.41 0 0 1 .75-.2.78.78 0 0 1 .46.11.37.37 0 0 1 .07.3zm-.49 0a.2.2 0 0 0 -.06-.14.15.15 0 0 0 -.14-.07.48.48 0 0 0 -.3.11 1 1 0 0 0 -.23.26 1.84 1.84 0 0 0 -.16.31l-.07.2a1.63 1.63 0 0 0 .71-.26.58.58 0 0 0 .25-.42z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m110.55 154.09a2.78 2.78 0 0 1 -.19.27 1.17 1.17 0 0 1 -.28.26.73.73 0 0 1 -.38.11.4.4 0 0 1 -.27-.09.29.29 0 0 1 -.09-.22 1 1 0 0 1 .06-.29l.17-.56a1.25 1.25 0 0 0 .08-.31.12.12 0 0 0 0-.09.11.11 0 0 0 -.09 0 .49.49 0 0 0 -.31.21 3.32 3.32 0 0 0 -.37.57 4.57 4.57 0 0 0 -.33.78h-.55l.44-1.36a1.48 1.48 0 0 0 .06-.24.17.17 0 0 0 0-.05 0 0 0 0 0 0 0 .23.23 0 0 0 -.18.11 1.48 1.48 0 0 0 -.14.25h-.19a1.34 1.34 0 0 1 .27-.44.63.63 0 0 1 .47-.2.42.42 0 0 1 .27.1.32.32 0 0 1 .09.21.82.82 0 0 1 0 .18c0 .09 0 .16-.06.23l-.08.24a3.86 3.86 0 0 1 .55-.69.73.73 0 0 1 .56-.25.31.31 0 0 1 .26.1.4.4 0 0 1 .08.23 1.27 1.27 0 0 1 -.06.28l-.25.8a1 1 0 0 0 0 .21s0 0 0 .05a.31.31 0 0 0 .17-.09.94.94 0 0 0 .18-.24z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m112.84 152.87a.58.58 0 0 1 -.14.25 4.81 4.81 0 0 1 -.39.38 4.32 4.32 0 0 1 -.59.45l-.49.35.22-.05h.23a1.13 1.13 0 0 1 .36.06h.16a.29.29 0 0 0 .17 0l.11-.13h.21a1.64 1.64 0 0 1 -.16.28 1 1 0 0 1 -.27.26.63.63 0 0 1 -.36.1.6.6 0 0 1 -.2 0 .66.66 0 0 1 -.18-.1 1 1 0 0 0 -.2-.11.29.29 0 0 0 -.16 0 .24.24 0 0 0 -.22.13l-.08.11a.12.12 0 0 1 -.09 0 .09.09 0 0 1 -.08 0 .11.11 0 0 1 0-.07.37.37 0 0 1 .12-.23 2 2 0 0 1 .3-.26l.52-.38a9.05 9.05 0 0 0 .75-.59.9.9 0 0 1 -.36.06h-.14-.19-.2a.25.25 0 0 0 -.16 0 .73.73 0 0 0 -.1.13h-.23a1.66 1.66 0 0 1 .26-.41.63.63 0 0 1 .46-.19.93.93 0 0 1 .39.14l.11.07h.09.07a.25.25 0 0 0 .21-.15.22.22 0 0 1 .06-.07.08.08 0 0 1 .07 0 .13.13 0 0 1 .09 0 .13.13 0 0 1 .03-.03z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m113.77 154.66a.83.83 0 0 1 -.12.41 1.09 1.09 0 0 1 -.35.36 1.28 1.28 0 0 1 -.58.18l.07-.21a1 1 0 0 0 .53-.24.53.53 0 0 0 .17-.29.08.08 0 0 0 0-.06h-.07a.34.34 0 0 1 -.2-.08.3.3 0 0 1 -.09-.23.29.29 0 0 1 .09-.22.33.33 0 0 1 .22-.08.29.29 0 0 1 .26.15.57.57 0 0 1 .07.31z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m90.77 157-.85 2.66a2 2 0 0 0 -.06.24.07.07 0 0 0 .08.08.24.24 0 0 0 .16-.09.74.74 0 0 0 .18-.23h.22a2.06 2.06 0 0 1 -.15.24 1.55 1.55 0 0 1 -.26.25.65.65 0 0 1 -.36.1c-.27 0-.4-.15-.4-.43v-.07a1 1 0 0 1 -.22.23 1.32 1.32 0 0 1 -.29.19.78.78 0 0 1 -.33.07.47.47 0 0 1 -.38-.16.61.61 0 0 1 -.13-.41 1.39 1.39 0 0 1 .11-.52 1.78 1.78 0 0 1 .28-.48 1.46 1.46 0 0 1 .42-.33 1 1 0 0 1 .5-.13.56.56 0 0 1 .29.07 1 1 0 0 1 .17.18l.28-.9a.85.85 0 0 0 0-.18.1.1 0 0 0 0-.09.15.15 0 0 0 -.1 0h-.18l.06-.21h.59.2zm-1.15 1.75a.41.41 0 0 0 -.08-.25.26.26 0 0 0 -.2-.1.53.53 0 0 0 -.39.2 1.44 1.44 0 0 0 -.3.49 1.25 1.25 0 0 0 -.12.5.28.28 0 0 0 .26.3.53.53 0 0 0 .3-.11 1.63 1.63 0 0 0 .28-.3 2.12 2.12 0 0 0 .18-.37 1.27 1.27 0 0 0 .07-.35z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m92 159.67a1.64 1.64 0 0 1 -.29.41.63.63 0 0 1 -.48.21.4.4 0 0 1 -.29-.1.3.3 0 0 1 -.1-.23 1.18 1.18 0 0 1 .09-.38l.31-.81a.54.54 0 0 0 0-.15.06.06 0 0 0 0-.05c-.06 0-.11 0-.18.12a1.54 1.54 0 0 0 -.14.22h-.2a1.09 1.09 0 0 1 .36-.47.72.72 0 0 1 .44-.17.41.41 0 0 1 .25.08.23.23 0 0 1 .1.2.66.66 0 0 1 -.06.27l-.37 1a.62.62 0 0 0 0 .16.09.09 0 0 0 0 .06.21.21 0 0 0 .15-.07 1.63 1.63 0 0 0 .19-.27zm.19-2.35a.29.29 0 0 1 -.09.22.29.29 0 0 1 -.21.09.32.32 0 0 1 -.21-.1.29.29 0 0 1 0-.42.29.29 0 0 1 .22-.09.42.42 0 0 1 .15 0l.11.11a.27.27 0 0 1 .02.19z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m94.27 158.67a.48.48 0 0 1 -.06.21.84.84 0 0 1 -.22.27 1.38 1.38 0 0 1 -.44.24 3.06 3.06 0 0 1 -.72.14h-.06a1.11 1.11 0 0 0 0 .17.34.34 0 0 0 .08.25.36.36 0 0 0 .24.08.89.89 0 0 0 .67-.37h.24a1.81 1.81 0 0 1 -.44.43 1.12 1.12 0 0 1 -.7.21.78.78 0 0 1 -.48-.15.52.52 0 0 1 -.19-.44 1.34 1.34 0 0 1 .2-.69 1.5 1.5 0 0 1 .55-.54 1.38 1.38 0 0 1 .74-.21.75.75 0 0 1 .46.12.34.34 0 0 1 .13.28zm-.49 0a.24.24 0 0 0 0-.14.26.26 0 0 0 -.15-.07.48.48 0 0 0 -.3.11 1.18 1.18 0 0 0 -.23.27 3 3 0 0 0 -.16.31l-.07.19a1.51 1.51 0 0 0 .71-.26.5.5 0 0 0 .2-.43z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m97.93 159a1.21 1.21 0 0 1 -.12.48 1.31 1.31 0 0 1 -.31.44 1.6 1.6 0 0 1 -.45.32 1.41 1.41 0 0 1 -.54.11.82.82 0 0 1 -.58-.2.55.55 0 0 1 -.2-.44 1.6 1.6 0 0 1 .09-.45l.52-1.65a.7.7 0 0 0 0-.14.1.1 0 0 0 -.07-.1.53.53 0 0 0 -.2 0h-.07l.07-.21a3.85 3.85 0 0 0 .79-.05h.16l-.5 1.56.12-.09a1.12 1.12 0 0 1 .15-.11.76.76 0 0 1 .2-.08.86.86 0 0 1 .28 0 .68.68 0 0 1 .31.08.54.54 0 0 1 .22.23.72.72 0 0 1 .13.3zm-.57 0a.47.47 0 0 0 -.08-.28.25.25 0 0 0 -.23-.11c-.26 0-.47.18-.64.56a1.71 1.71 0 0 0 -.13.38 1.6 1.6 0 0 0 0 .27.36.36 0 0 0 .08.23.32.32 0 0 0 .24.09.52.52 0 0 0 .4-.14 1.22 1.22 0 0 0 .28-.48 2 2 0 0 0 .08-.52z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m100.26 158.72a.49.49 0 0 1 -.06.22.69.69 0 0 1 -.21.26 1.21 1.21 0 0 1 -.44.25 3.26 3.26 0 0 1 -.73.14.52.52 0 0 0 0 .17.3.3 0 0 0 .08.25.33.33 0 0 0 .24.08.91.91 0 0 0 .67-.37h.19a1.51 1.51 0 0 1 -.44.43 1.15 1.15 0 0 1 -.69.21.76.76 0 0 1 -.48-.15.5.5 0 0 1 -.19-.45 1.34 1.34 0 0 1 .2-.68 1.47 1.47 0 0 1 .54-.54 1.42 1.42 0 0 1 .75-.21.7.7 0 0 1 .46.12.3.3 0 0 1 .11.27zm-.49 0a.16.16 0 0 0 0-.14.17.17 0 0 0 -.14-.07.48.48 0 0 0 -.3.11 1.18 1.18 0 0 0 -.23.27 1.3 1.3 0 0 0 -.16.31l-.07.19a1.63 1.63 0 0 0 .71-.26.52.52 0 0 0 .19-.42z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m102.72 158.54a.12.12 0 0 1 0 .11.19.19 0 0 1 -.22 0 .11.11 0 0 0 -.07 0 .09.09 0 0 0 -.09.07.61.61 0 0 1 .07.14.5.5 0 0 1 0 .19.74.74 0 0 1 0 .22.46.46 0 0 1 -.15.23.78.78 0 0 1 -.31.19 1.3 1.3 0 0 1 -.51.08.85.85 0 0 0 -.39.05.15.15 0 0 0 -.1.14.11.11 0 0 0 0 .08l.11.06.25.08.35.1c.42.12.63.32.63.59a.59.59 0 0 1 -.13.32.8.8 0 0 1 -.4.24 2.41 2.41 0 0 1 -.73.09 2.27 2.27 0 0 1 -.42 0 1.29 1.29 0 0 1 -.44-.16.36.36 0 0 1 -.2-.34.39.39 0 0 1 0-.2.44.44 0 0 1 .13-.14.3.3 0 0 1 .15 0h.07a.05.05 0 0 1 0 .05.21.21 0 0 1 0 .09.34.34 0 0 0 -.08.18.3.3 0 0 0 .25.3 1.83 1.83 0 0 0 .52.09 1.28 1.28 0 0 0 .52-.09c.13-.06.19-.13.19-.23a.14.14 0 0 0 0-.11.33.33 0 0 0 -.14-.09l-.41-.14a4 4 0 0 1 -.41-.16.44.44 0 0 1 -.21-.16.33.33 0 0 1 -.08-.22.39.39 0 0 1 .17-.32.88.88 0 0 1 .4-.15.6.6 0 0 1 -.27-.22.56.56 0 0 1 -.08-.3.6.6 0 0 1 .12-.36.91.91 0 0 1 .33-.28 1.06 1.06 0 0 1 .48-.1.85.85 0 0 1 .59.19 1 1 0 0 1 .17-.13.36.36 0 0 1 .19 0 .25.25 0 0 1 .15 0 .19.19 0 0 1 0 .02zm-.83.27a.32.32 0 0 0 0-.14.31.31 0 0 0 -.09-.11.33.33 0 0 0 -.14 0 .33.33 0 0 0 -.26.13.87.87 0 0 0 -.17.3.93.93 0 0 0 -.06.3.29.29 0 0 0 .07.21.24.24 0 0 0 .18.07.39.39 0 0 0 .37-.25 1.08 1.08 0 0 0 .1-.51z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m104.83 158.77a.56.56 0 0 1 -.06.22.82.82 0 0 1 -.22.26 1.38 1.38 0 0 1 -.44.24 2.62 2.62 0 0 1 -.72.14h-.06a1.24 1.24 0 0 0 0 .18.28.28 0 0 0 .32.32.91.91 0 0 0 .67-.37h.24a1.66 1.66 0 0 1 -.44.44 1.13 1.13 0 0 1 -.69.21.84.84 0 0 1 -.48-.16.52.52 0 0 1 -.19-.44 1.34 1.34 0 0 1 .2-.69 1.67 1.67 0 0 1 .55-.54 1.29 1.29 0 0 1 .74-.2.75.75 0 0 1 .46.11.35.35 0 0 1 .12.28zm-.49 0a.22.22 0 0 0 -.06-.14.17.17 0 0 0 -.14-.06.43.43 0 0 0 -.3.11 1 1 0 0 0 -.23.26 2.29 2.29 0 0 0 -.16.31l-.07.2a1.63 1.63 0 0 0 .71-.26.56.56 0 0 0 .25-.44z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m106.27 159.81a1.64 1.64 0 0 1 -.29.41.63.63 0 0 1 -.48.21.51.51 0 0 1 -.3-.1.33.33 0 0 1 -.09-.23 1.18 1.18 0 0 1 .09-.38l.31-.81a.54.54 0 0 0 0-.15.08.08 0 0 0 0 0s-.11 0-.17.12a1.13 1.13 0 0 0 -.14.22h-.2a1.27 1.27 0 0 1 .36-.47.76.76 0 0 1 .44-.17.42.42 0 0 1 .26.08.26.26 0 0 1 .1.2.66.66 0 0 1 -.06.27l-.37 1a.62.62 0 0 0 0 .16.08.08 0 0 0 0 .05s0 0 0 0a.21.21 0 0 0 .15-.07 1.23 1.23 0 0 0 .18-.27zm.19-2.35a.29.29 0 0 1 -.09.22.25.25 0 0 1 -.21.08.29.29 0 0 1 -.22-.09.32.32 0 0 1 -.08-.21.29.29 0 0 1 .09-.21.29.29 0 0 1 .22-.09.42.42 0 0 1 .15 0 .35.35 0 0 1 .1.11.28.28 0 0 1 .04.19z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m108.49 158.81a.25.25 0 0 1 -.06.17.23.23 0 0 1 -.18.07.25.25 0 0 1 -.13 0l-.07-.09s0-.1-.07-.18a.19.19 0 0 0 -.07-.12.26.26 0 0 0 -.18 0 .43.43 0 0 0 -.22.06.22.22 0 0 0 -.1.17.33.33 0 0 0 0 .13l.1.12a1.42 1.42 0 0 0 .17.16 2 2 0 0 1 .33.32.46.46 0 0 1 .1.31.5.5 0 0 1 -.09.29.59.59 0 0 1 -.3.22 1.14 1.14 0 0 1 -.52.08 1 1 0 0 1 -.64-.17.48.48 0 0 1 -.18-.33.24.24 0 0 1 .07-.18.26.26 0 0 1 .19-.08c.16 0 .25.1.27.31a.4.4 0 0 0 .07.2.3.3 0 0 0 .24.08.34.34 0 0 0 .25-.08.22.22 0 0 0 .1-.2.18.18 0 0 0 0-.12.48.48 0 0 0 -.14-.16l-.3-.28a1.21 1.21 0 0 1 -.19-.23.53.53 0 0 1 -.06-.24.52.52 0 0 1 .08-.24.69.69 0 0 1 .27-.22 1.38 1.38 0 0 1 .54-.09 1.79 1.79 0 0 1 .36 0 .76.76 0 0 1 .22.1.38.38 0 0 1 .1.13.31.31 0 0 1 .04.09z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m110.2 158.5-.07.22h-.46l-.36 1.15v.17a.13.13 0 0 0 0 .09.09.09 0 0 0 .08 0 .28.28 0 0 0 .24-.12 2.82 2.82 0 0 0 .18-.29h.19a1.54 1.54 0 0 1 -.3.44.77.77 0 0 1 -.31.21.63.63 0 0 1 -.26.05.41.41 0 0 1 -.31-.12.45.45 0 0 1 -.11-.31 1.25 1.25 0 0 1 .08-.36l.31-1h-.44l.07-.21h.44l.22-.7h.61l-.22.71z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m112.31 158.84a.52.52 0 0 1 -.06.22.82.82 0 0 1 -.22.26 1.38 1.38 0 0 1 -.44.24 2.62 2.62 0 0 1 -.72.14h-.06a1.11 1.11 0 0 0 0 .17.36.36 0 0 0 .08.25.33.33 0 0 0 .24.07.87.87 0 0 0 .67-.37h.2a1.87 1.87 0 0 1 -.45.43 1.13 1.13 0 0 1 -.69.21.71.71 0 0 1 -.48-.16.51.51 0 0 1 -.19-.44 1.4 1.4 0 0 1 .2-.69 1.67 1.67 0 0 1 .55-.54 1.47 1.47 0 0 1 .74-.2.75.75 0 0 1 .46.11.37.37 0 0 1 .17.3zm-.49 0a.2.2 0 0 0 -.06-.14.15.15 0 0 0 -.14-.07.48.48 0 0 0 -.3.11 1.13 1.13 0 0 0 -.23.26 3.07 3.07 0 0 0 -.16.31l-.07.2a1.63 1.63 0 0 0 .71-.26.55.55 0 0 0 .25-.42z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m114.54 158.78a.39.39 0 0 1 -.09.26.24.24 0 0 1 -.2.11.26.26 0 0 1 -.2-.12c0-.05-.08-.08-.11-.08s-.1 0-.17.12a1.66 1.66 0 0 0 -.23.31 3.61 3.61 0 0 0 -.23.44 4.65 4.65 0 0 0 -.2.52v.09h-.54l.37-1.3a1.07 1.07 0 0 0 .06-.26.13.13 0 0 0 0-.06s0 0 0 0a.27.27 0 0 0 -.19.13 1.18 1.18 0 0 0 -.14.23h-.21a1.61 1.61 0 0 1 .28-.42.94.94 0 0 1 .27-.2.6.6 0 0 1 .24-.06.28.28 0 0 1 .24.12.68.68 0 0 1 0 .23 2.48 2.48 0 0 1 -.14.64l.22-.37.21-.28a1.34 1.34 0 0 1 .25-.23.41.41 0 0 1 .26-.1c.12 0 .19 0 .23.11a.39.39 0 0 1 .02.17z"
              transform="translate(-36.24 -47.61)"
            />
            <path
              d="m116.09 158.56-.06.21h-.46l-.37 1.14a1 1 0 0 0 0 .18.16.16 0 0 0 0 .09.1.1 0 0 0 .08 0 .3.3 0 0 0 .24-.13 2 2 0 0 0 .19-.28h.22a1.6 1.6 0 0 1 -.3.45.77.77 0 0 1 -.31.21.91.91 0 0 1 -.26.05.4.4 0 0 1 -.31-.12.45.45 0 0 1 -.11-.31 1.59 1.59 0 0 1 .07-.36l.32-1h-.44l.07-.21h.43l.22-.71h.55l-.22.71z"
              transform="translate(-36.24 -47.61)"
            />
        </g>

          <path
          class="thelogo__outline--4"
            d="m101.9 178-.42-.27c-4.06-2.59-7.94-5.3-11.55-8.06a141.65 141.65 0 0 1 -12.66-10.92 123 123 0 0 1 -11-12.32 110.61 110.61 0 0 1 -16.4-29.43 121 121 0 0 1 -5-17.44 141.09 141.09 0 0 1 -2.56-19.56 170 170 0 0 1 -.13-17.68v-.6l.58-.14c7.42-1.81 15-3.32 22.5-4.47a247 247 0 0 1 29.34-2.77c2.52-.08 5.09-.11 7.66-.09a215.64 215.64 0 0 1 31.85 2.83c10.83 1.71 21 4 28.61 5.81l.6.14v.61a161.15 161.15 0 0 1 -.32 17.43 134.52 134.52 0 0 1 -2.87 19.38 117.77 117.77 0 0 1 -5.32 17.3 112 112 0 0 1 -17 29.16 124.25 124.25 0 0 1 -11.24 12.18 143.48 143.48 0 0 1 -12.77 10.76c-3.59 2.7-7.45 5.34-11.48 7.87zm-58.15-115c-.23 5.81-.18 11.5.15 16.92a137.64 137.64 0 0 0 2.55 19.32 116.68 116.68 0 0 0 4.92 17.22 108.75 108.75 0 0 0 16.14 29 119.78 119.78 0 0 0 10.88 12.14 140 140 0 0 0 12.51 10.8c3.44 2.63 7.14 5.22 11 7.7 3.84-2.42 7.52-4.95 11-7.52a143.37 143.37 0 0 0 12.62-10.65 123.46 123.46 0 0 0 11.1-12 113.47 113.47 0 0 0 9.36-13.54 111.2 111.2 0 0 0 7.41-15.2 116.57 116.57 0 0 0 5.24-17.07 131.28 131.28 0 0 0 2.84-19.16 156.77 156.77 0 0 0 .36-16.62c-7.53-1.77-17.4-4-27.9-5.65a213.81 213.81 0 0 0 -31.62-2.8c-2.54 0-5.09 0-7.58.09a245.64 245.64 0 0 0 -29.19 2.72c-7.27 1.12-14.6 2.57-21.79 4.3z"
            transform="translate(-36.24 -47.61)"
          />
          <path
          class="thelogo__outline--5"
            d="m101.9 178-.42-.27c-4.06-2.59-7.94-5.3-11.55-8.06a141.65 141.65 0 0 1 -12.66-10.92 123 123 0 0 1 -11-12.32 110.61 110.61 0 0 1 -16.4-29.43 121 121 0 0 1 -5-17.44 141.09 141.09 0 0 1 -2.56-19.56 170 170 0 0 1 -.13-17.68v-.6l.58-.14c7.42-1.81 15-3.32 22.5-4.47a247 247 0 0 1 29.34-2.77c2.52-.08 5.09-.11 7.66-.09a215.64 215.64 0 0 1 31.85 2.83c10.83 1.71 21 4 28.61 5.81l.6.14v.61a161.15 161.15 0 0 1 -.32 17.43 134.52 134.52 0 0 1 -2.87 19.38 117.77 117.77 0 0 1 -5.32 17.3 112 112 0 0 1 -17 29.16 124.25 124.25 0 0 1 -11.24 12.18 143.48 143.48 0 0 1 -12.77 10.76c-3.59 2.7-7.45 5.34-11.48 7.87zm-58.15-115c-.23 5.81-.18 11.5.15 16.92a137.64 137.64 0 0 0 2.55 19.32 116.68 116.68 0 0 0 4.92 17.22 108.75 108.75 0 0 0 16.14 29 119.78 119.78 0 0 0 10.88 12.14 140 140 0 0 0 12.51 10.8c3.44 2.63 7.14 5.22 11 7.7 3.84-2.42 7.52-4.95 11-7.52a143.37 143.37 0 0 0 12.62-10.65 123.46 123.46 0 0 0 11.1-12 113.47 113.47 0 0 0 9.36-13.54 111.2 111.2 0 0 0 7.41-15.2 116.57 116.57 0 0 0 5.24-17.07 131.28 131.28 0 0 0 2.84-19.16 156.77 156.77 0 0 0 .36-16.62c-7.53-1.77-17.4-4-27.9-5.65a213.81 213.81 0 0 0 -31.62-2.8c-2.54 0-5.09 0-7.58.09a245.64 245.64 0 0 0 -29.19 2.72c-7.27 1.12-14.6 2.57-21.79 4.3z"
            transform="translate(-36.24 -47.61)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { onMounted, ref } from 'vue';
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

export default {
  props:{animation:Boolean},
  setup(props) {
    const { getPath } = getPathFunction();

    const LOGO = ref(null);

    function startAnimating(){
      let bg_1= LOGO.value.querySelector(".thelogo__bg--1")
      let bg_2 = LOGO.value.querySelector(".thelogo__bg--2")
     
     let x_1= LOGO.value.querySelector(".thelogo__outline--2")
     let x_2= LOGO.value.querySelector(".thelogo__outline--3")
     let test_4= LOGO.value.querySelector(".thelogo__outline--4")
      let test_5 = LOGO.value.querySelector(".thelogo__outline--5")

      
     let g_1= LOGO.value.querySelector(".thelogo__g--1");
     let p_k= LOGO.value.querySelector(".thelogo__path--k");
     let p_f= LOGO.value.querySelector(".thelogo__path--f");
      let p_z = LOGO.value.querySelector(".thelogo__path--z");
      let p_w = LOGO.value.querySelector(".thelogo__path--w");

      gsap.from(bg_1, { duration: 1,opacity:0, scale: 0, transformOrigin: "50% 50%" , ease:"power4.inOut", delay:0});
      gsap.from(bg_2, { duration: 1, scale: 0, transformOrigin: "50% 50%" , ease:"power4.inOut", delay:0.2});

      gsap.from(test_4, {
        delay: 0.8,
        duration: 2, drawSVG: "0 0", 
        ease:"power4.inOut"
      })

      gsap.to( test_5, {opacity:1, delay:1.6})
          gsap.to( test_4, {opacity:0, delay:1.6})

      var del_2 = 1.4;
      gsap.from( x_1, { duration:0.5, scale:0, opacity:0,transformOrigin:"0% 100%", delay:del_2+ 0 })
      gsap.from(x_2, { duration: 0.5,  scale: 0, opacity: 0, transformOrigin: "100% 100%", delay: del_2+0.2 })

      let del = 1.5;

      let easy= "power4.inOut";

      gsap.from(p_k, { duration: 1, rotation: 0, scale: 0,ease: easy, transformOrigin: "50% 50%", delay:del+ 0 })
      gsap.from(p_f, { duration: 1, rotation: 0, scale: 0, ease: easy,transformOrigin: "50% 50%" , delay:del+ 0.1})
      gsap.from(p_z, { duration: 1, rotation: 0, scale: 0,ease: easy, transformOrigin: "50% 50%", delay:del+ 0.2 })
      gsap.from(p_w, { duration: 2, rotation: 0, scale: 0, ease: "elastic.out(1,0.3)",transformOrigin: "50% 50%", delay:del+ 0.3 })


      gsap.from( g_1, { duration:1,  opacity:0,transformOrigin:"50% 50%", delay:del+1 })

    }

    onMounted(() => {

      console.log("props.animation", props.animation);
        if (props.animation) {
          startAnimating();
        }

    
// 
    })

    return { getPath,LOGO,startAnimating };
  },
};
</script>

<style lang="scss" scoped>

.thelogo__outline--1{
  fill: white;
}
.thelogo__outline--4{
  fill: none;
  stroke-width: 1;
  stroke: white;
}
.thelogo__outline--5{
  fill: white;
  opacity: 0;
}
.thelogo__svg {
  width: 100%;
  height: 100%;
}
.thelogo_png {
  width: 100%;
  height: auto;
}
.thelogo__color {
  fill: white;
}

.thelogo {
  &.isblack {
    .thelogo__color {
      fill: black;
    }
  }
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
.st2 {
  fill: #ffffff;
}
</style>
